import sendRequest from "@/libs/sendRequest";

export const program = {
    namespaced: true,
    state: {
        products: [],
        product: {},
        categories: [],
        redeems: [],
        redeem: {},
        cart_products: [],
        error: false
    },
    getters: {
        getProducts: (state) =>(state.products),
        getProduct: (state) => (state.product),
        getCategories: (state) => (state.categories),
        getRedeems: (state) => (state.redeems),
        getRedeem: (state) => (state.redeem),
        getCartProducts: (state) => {
            state.cart_products =  JSON.parse(sessionStorage.getItem('wiks@redeem_products')) || []
            console.log(state.products);
            return state.cart_products;
        },
        getError: (state) => (state.error)
    },
    mutations: {
        setProducts: (state, payload) => (state.products = payload),
        setProduct: (state, payload) => state.product = payload,
        setCategories: (state, payload) => state.categories = payload,
        setRedeems: (state, payload) => state.redeems = payload,
        setRedeem: (state, payload) => state.redeem = payload,
        setError: (state, payload) => state.error = payload,
        setCartProducts: (state, payload) => {
            sessionStorage.setItem('wiks@redeem_products', JSON.stringify(payload))
            state.cart_products = payload
        },
    },
    actions: {
        list: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/program/products/list','GET', null, null, null, custom_headers);

            console.log('response', response);

            if(response.success && response.data) {

                let products = response.data.list ?? [];

                products = products.map((product) => {
                    return {
                        ...product,
                        Imagen_1: `https://qa-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`,
                        Imagen_2: `https://qa-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`,
                        Imagen_3: `https://qa-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`,
                    }
                })

                console.log('STORE =>',products);

                commit('setProducts', products) 
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        },
        view: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/program/products/view','GET', null, null, {id: id}, custom_headers);

            if(response.success && response.data) {

                const product = response.data.list

                product.Imagen_1 = `https://qa-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`
                product.Imagen_2 = `https://qa-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`
                product.Imagen_3 = `https://aq-api.wiks-services.online/websales/program/products/resources?id=${product.ID}`

                commit('setProduct', response.data.list ?? {}) 
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        },
        listCategories: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/program/products/categories','GET', null, null, null, custom_headers);

            if(response.success && response.data) {
                commit('setCategories', response.data.list ?? []) 
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        },
        listPointRedeems: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/program/program/history', 'GET', null, null, null, custom_headers);

            if(response.success && response.data) {
                commit('setRedeems', response.data.list ?? [])
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        viewPointRedeem: async({commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/program/program/detail' ,'GET', null, null, id, custom_headers);

            if(response.success && response.data) {
                response.data.list.Productos = JSON.parse(response.data.list.Productos);

                commit('setRedeem', response.data.list ?? [])
                commit('setAlert', {
                    open: true,
                    message: 'Información actualizada con éxito',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
            }

            else if(!response.success && response.errors) {
                console.log(response.errors);
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        pointCheckout: async({commit, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/program/shop/checkout' ,'POST', null, data, null, custom_headers);

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Proceso realizado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
                commit('setCartProducts', [])
            }

            else if(!response.success && response.errors) {
                let messages = [];

                for(let message in response.errors) {
                    switch (response.errors[message].code) {
                        case 'points_not_enough':
                            messages.push('Lo sentimos, parace que no tienes los puntos suficientes.');
                            break;
                        default:
                            messages.push('Un error ha ocurrido intente más tarde.');
                            break;
                    }
                }

                commit('setError', true);
                commit('setAlert', {
                    open: true,
                    message: messages,
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        updateCart: ({commit, rootGetters}, data) => {
            commit('setCartProducts', data)
            commit('setAlert', {
                open: true,
                message: 'Carrito actualizado con éxito',
                variant: 'success',
                text_color: 'white',
                timeout: 3000
            }, { root: true})
        },
        removeFromCart: ({commit, rootGetters, getters}, product_id) => {
            let cart_products = getters.getCartProducts;

            let product_index = cart_products.findIndex((product) => product.id === product_id);

            cart_products[product_index].qty--;

            if(cart_products[product_index].qty === 0) {
                delete cart_products[product_index];
                cart_products.filter((product) => true);
                commit('setCartProducts', cart_products);
            } else {
                commit('setCartProducts', cart_products);
            }
        }
    }
}